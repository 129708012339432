<template>
  <div class="tw-px-5 login">

  </div>

</template>

<script>

// import BaseButton from "@/components/UIComponents/BaseButton";
import {getUserFirstLogin} from "@/services/api/APIService";
import VueJwtDecode from 'vue-jwt-decode'
import {mapGetters} from "vuex";

export default {
  name: "Login",
  // components: {BaseButton},
  data: () => {
    return {
      userData: {
        email: "",
        password: "",
      },
      autoEmail: false,
      value: false,
      isSubmitting: false

    }
  },
  computed:{
    ...mapGetters("onBoarding",["name"]),
    getUserTokenData(){
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  // created() {
  //   if (this.$route.query.email) {
  //     this.userData.email = this.$route.query.email
  //     this.autoEmail = true
  //   }
  // },
  // methods: {
  //   gotoReset() {
  //     this.$router.push('/reset')
  //   },
  //   logOut() {
  //     this.$store.dispatch("onBoarding/userLogOut")
  //     this.$router.push('/')
  //   },
  //
  //   async handleLogin() {
  //     this.isSubmitting = true;
  //     await this.$store.dispatch("onBoarding/userLogin", this.userData).then((res) => {
  //       console.log(res)
  //       getUserFirstLogin()
  //       .then(response=>{
  //         // app_first_login: false
  //         // auth_first_login: false
  //         if(this.$route.query.company_name && this.$route.query.site_name){
  //           sessionStorage.setItem('adminDetails', JSON.stringify({
  //             siteName: this.$route.query.site_name
  //           }))
  //           this.$router.push({name: 'InviteAnAdminView', params: {step: '1'}})
  //         }
  //         else{
  //           if (response.data.app_first_login&&
  //               response.data.auth_first_login) {
  //             this.$router.push({name: 'CreateCompanyProfileView', params: {step: '1'}})
  //
  //           }
  //           else if(!response.data.app_first_login&&
  //               !response.data.auth_first_login){
  //             this.$router.push({name: 'ViewAllOrganizationPage'})
  //
  //           }
  //           else{
  //             this.$router.push({name: 'CreateCompanyProfileView', params: {step: '1'}})
  //
  //           }
  //         }
  //         console.log(response.data)
  //         // if (response.data.is_first_login) {
  //         //   this.$router.push({name: 'CreateCompanyProfileView', params: {step: '1'}})
  //         // } else if (this.$route.query.company_name && this.$route.query.site_name) {
  //         //   sessionStorage.setItem('adminDetails', JSON.stringify({
  //         //     siteName: this.$route.query.site_name
  //         //   }))
  //         //   this.$router.push({name: 'InviteAnAdminView', params: {step: '1'}})
  //         // } else this.$router.push({name: 'ViewAllOrganizationPage'})
  //         this.isSubmitting = false
  //       })
  //       .catch(error=>{
  //         this.isSubmitting = false;
  //
  //         console.log(error)
  //       })
  //
  //
  //     })
  //         .catch(error => {
  //           console.log(error)
  //           this.isSubmitting = false
  //           let errorKey = Object.keys(error.response.data)
  //           this.$displaySnackbar({
  //             message: error.response.data[errorKey[0]],
  //             success: false
  //           })
  //         })
  //
  //
  //   }
  // },
  async beforeMount() {
    const { $route: { query: { token, company_name, site_name, interviewer, job_id } } } = this
    console.log(company_name, job_id, 'hi')
    if (token) {
      sessionStorage.setItem("userToken", token)
      const userData = VueJwtDecode.decode(token)
      this.$store.commit('onBoarding/loginSuccess', userData)
      if(interviewer){
        let userToken = {...this.getUserTokenData}
        userToken.site_name = site_name
        sessionStorage.setItem("userData", JSON.stringify(userToken));
        await this.$router.push({name: 'AllCandidates', params:{id:job_id}})
      }
      else if (company_name && site_name) {
        sessionStorage.setItem('adminDetails', JSON.stringify({
          siteName: site_name
        }))
        await this.$router.push({name: 'InviteAnAdminView', params: {step: '1'}})
      } else {
        try {
          const { data: { app_first_login, auth_first_login, admin_site_name , site_name} } = await getUserFirstLogin()
          if (app_first_login && auth_first_login) {
            await this.$router.push({name: 'CreateCompanyProfileView', params: {step: '1'}})
          } else if (admin_site_name || site_name) {
            let userToken = {...this.getUserTokenData}
            userToken.site_name = admin_site_name || site_name
            sessionStorage.setItem("userData", JSON.stringify(userToken));
            await this.$router.push({name: 'RecruiterDashboard'})
          } else await this.$router.push({name: 'CreateCompanyProfileView', params: {step: '1'}})
        } catch (e) {
          console.log(e)
          this.$displaySnackbar('An error occurred, try again later')
          // window.location.href = process.env.VUE_APP_FRONTEND_AUTH_URL
        }
      }
    }
  }
}
</script>


<!--<style lang="scss" scoped>-->
<!--.login {-->
<!--  margin: 10rem auto;-->
<!--  @media (min-width: 768px) {-->
<!--    align-items: center;-->
<!--    justify-content: center;-->
<!--    max-width: 400px;-->

<!--  }-->

<!--}-->

<!--.login-title {-->
<!--  font-size: 24px;-->
<!--}-->

<!--.name {-->
<!--  font-size: 14px;-->
<!--  font-weight: 400;-->
<!--  margin-bottom: 1rem;-->
<!--  font-family: DM Sans, serif;-->
<!--}-->

<!--.err {-->
<!--  color: red;-->
<!--  font-size: 14px;-->
<!--}-->

<!--.reDirect {-->
<!--  font-weight: 500;-->
<!--  font-size: 14px;-->
<!--  color: #008EEF;-->
<!--  letter-spacing: 1px;-->
<!--  font-family: DM Sans, serif;-->
<!--  cursor: pointer-->


<!--}-->


<!--</style>-->
