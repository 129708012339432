<template>
<Onboardlayout>
  <template v-slot:header>
    <Header>
      <template v-slot:right-header-component>
        <span class="tw-text-enumGrey3 already">
          Don't have an account?<span class="ml-3 reDirect" @click="gotoSignUp">Sign Up</span>
        </span>

      </template>
    </Header>
  </template>
  <template v-slot:main-content>
    <div>
      <Login/>
    </div>

  </template>
</Onboardlayout>
</template>

<script>
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import Login from "@/components/Login";
import Header from "@/components/onboarding/reuseables/Header";
export default {
name: "LoginPageView",
  components: {Header, Login, Onboardlayout},
  methods: {
    gotoSignUp(){
      this.$router.push({
        name: 'RegistrationPage',
        params: {type: 'business'}
      })
    }
  }
}
</script>

<style scoped>
.reDirect{
  font-weight: bold;
  font-size: 16px;
  color:#008EEF;
  letter-spacing: 1px;
  font-family: DM Sans, serif;
  cursor: pointer


}
.already {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

</style>
